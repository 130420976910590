import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  message,
  Pagination,
  Spin,
  Modal,
  Image as ImageAnt,
  Button,
} from "antd";
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import Images from "components/image";
// import store from 'store'

import "./merchant.less";
// import RightAffix from "../common/rightAffix/rightAffix";
// import Swiper3 from "../common/swiper3/swiper3";
import Nav from "../common/nav/nav";
import Header1 from "../common/header1/header1";
import PrivateHeader from "../common/PrivateHeader/PrivateHeader";
import PrivateTop from "../common/PrivateTop/PrivateTop";
import SearchTabStore from "../module/searchTabStore/searchTabStore";
import StoreClass from "../module/storeClass/storeClass";
import EmptyGoods from "../module/emptyGoods/emptygoods";

import Bottom from "../common/bottom/bottom";

import Storage from "utils/storage";
import { StoreGoods, GetIp, SetIp } from "api/merchandise";
import { storeClass, StoreInfo, StoreCodeMall } from "api/store";
import { SearchGoods } from "api/search";
import { GoodsImagesVideoV1, AddGoodsDownload } from "api/merchandise";

// import more from "../../assets/icon/more2.png";
import copyImg from "assets/icon/copy1.png";
import quick_look_img_icon from "assets/icon/quick_look_img_icon.png";
// import zan1 from "assets/icon/zan1.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
// import "swiper/components/parallax/parallax.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import TextArea from "antd/lib/input/TextArea";
SwiperCore.use([Autoplay]);

const Merchant = () => {
  const [swiper, setSwiper] = useState(2500);
  const history = useHistory();
  let store_id = history.location.pathname.split("/")[2];
  const [store_class, setDetails] = useState([]);
  const [store_name, setStore_names] = useState("");
  const [goods_list, setGoods_list] = useState([]);
  const [data, setData] = useState({});
  const [query, setQuery] = useState({
    page: 1,
    limit: 40,
    order: 6,
    store_id,
  });
  const [searchGoods, setSearchGoods] = useState([]);
  const [total, setTotal] = useState(0);
  const [active1, setActive1] = useState(-1);
  const [active2, setActive2] = useState(-1);
  const [color, setColor] = useState(1);
  const [member, setMember] = useState({});
  const [images, setImages] = useState([]);
  // const [images1, setImages1] = useState([]);
  const images1 = [];
  // const [index, setIndex] = useState(false);
  let index = false;
  // const [index1, setIndex1] = useState(false);
  const index1 = false;
  const [styleShow, setStyleShow] = useState(false);
  const [styleShow1, setStyleShow1] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [first, setFirst] = useState(false);
  const [store_info, setStoreInfo] = useState({});
  // const [bagShow, setBagShow] = useState(false);
  // const [checkAll, setCheckAll] = useState(false);
  // const [checkType, setCheckType] = useState("1");
  const [code, setCode] = useState("");

  // const [pageChange, setPageChange] = useState(false)
  // const allCheck = (type, check) => {
  //   // // console.log('check', type, check);
  //   setCheckType(type);
  //   setCheckAll(check);
  //   setSearchGoods(() => {
  //     let arr = searchGoods.map((k) => {
  //       k.checked = check;
  //       return { ...k };
  //     });
  //     return [...arr];
  //   });
  // };

  const [isIp, setIsIp] = useState(0);
  const [isSetIp, setIsSetIp] = useState(false);
  const [ipText, setIpText] = useState("");
  useEffect(() => {
    const getIp = async () => {
      const res = await GetIp();
      setIsIp(res?.datas || 0);
    };
    getIp();
  }, []);
  const setIp = async () => {
    if (!ipText) return message.error("请输入IP");
    const res = await SetIp({ ip_text: ipText });
    if (!res) return;
    if (res.ResponseStatus) return message.error(res.ResponseMsg);
    message.success("设置成功");
  };
  useEffect(() => {
    if (history?.location?.search === "?sz=1") {
      setIsSetIp(true);
    }
  }, [history.location]);

  useEffect(() => {
    if (history?.location?.state?.keyword) {
      setQuery((form) => {
        form.keywords = history?.location?.state?.keyword;
        return { ...form };
      });
    }
  }, [history.location.state]);

  useEffect(() => {
    global.private && Storage.setPRIVATE(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setTimeout(() => {
      if (Object.keys(data).length === 0 && Storage.getSetting()) {
        setData(() => {
          return JSON.parse(Storage.getSetting());
        });
      }
    }, 400);
    setMember(() => {
      if (Storage.getUser()) return JSON.parse(Storage.getUser());
      return {};
    });
  }, [data]);
  useEffect(() => {
    if (active1 === -1 && active2 === -1 && store_class.length > 0) {
      if (history.location.search) {
        let arr = history.location.search.replace("?", "").split("&");
        let i1 = "";
        let i2 = "";
        arr.forEach((item) => {
          if (item.indexOf("i1") > -1) {
            setActive1(+item.split("=")[1]);
            i1 = +item.split("=")[1];
          }
          if (item.indexOf("i2") > -1) {
            i2 = +item.split("=")[1];
            setTimeout(() => {
              setActive2(+item.split("=")[1]);
            }, 0);
          }
        });
        if (history.location.search.indexOf("i1") === -1) return;
        if (history.location.search.indexOf("i2") > -1) {
          setQuery(() => {
            query.store_class_id = store_class?.[i1]?.subs?.[i2]?.class_id;
            query.page = 1;
            return { ...query };
          });
        } else if (history.location.search.indexOf("i1") > -1) {
          setQuery(() => {
            query.store_class_id = store_class[i1].class_id;
            query.page = 1;
            return { ...query };
          });
        }
      }
    }
  }, [store_class]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // 2023/3/14 检索分类清空关键词
    if (query.keywords) {
      setActive1(-1);
      setActive2(-1);
    }
    if (
      (history.location.search.indexOf("i1") > -1 && query.store_class_id) ||
      history.location.search.indexOf("i1") === -1
    ) {
      if (query.store_id) getSearchGoods();
      if (global.private) {
        getSearchGoods();
      } // 私域
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps
  const getSearchGoods = async () => {
    setSpinning(true);
    const res = await SearchGoods(query);
    setSpinning(false);
    if (res.ResponseStatus !== 0) return;
    if (first || query.keywords) {
      document.body.scrollTop = 660;
      document.documentElement.scrollTop = 660;
    }
    setFirst(true);
    setSearchGoods([...res.datas.lists]);
    setTotal(res.datas.total);
    setColor(res.datas.is_color);
    setImages(() => {
      res.datas.lists.forEach((k, i) => {
        images[i] = -1;
      });
      return [...images];
    });
    if (res.datas.lists[0]) setStore_names(res.datas.lists[0].store_name);
  };

  const storeCodeMall = async () => {
    const res = await StoreCodeMall({
      store_id: store_id,
    });
    if (res.ResponseStatus === 0) {
      setCode(res.datas);
    }
  };
  const storeinfo = async () => {
    const res = await StoreInfo({
      store_id: store_id,
    });
    if (res.ResponseStatus === 0) {
      setStoreInfo({ ...res.datas });
      setQuery((form) => {
        form.order = res?.datas?.goods_sort === 2 ? 2 : 6;
        return { ...form };
      });
    }
  };
  const storeDate = async () => {
    const res = await storeClass({
      store_id: store_id,
    });
    if (res.ResponseStatus === 0) {
      setDetails([...res.datas]);
    }
  };
  const fetchData = async () => {
    const res = await StoreGoods({
      store_id: store_id,
      limit: 5,
    });
    if (res.ResponseStatus === 0) {
      setGoods_list([...res.datas.lists]);
    }
  };
  useEffect(() => {
    if (store_id) {
      storeCodeMall();
      storeinfo();
      fetchData();
      storeDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store_id]);
  useEffect(() => {
    if (global.private) {
      storeCodeMall();
      storeinfo();
      fetchData();
      storeDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (page, pageSize) => {
    setQuery(() => {
      query.page = page;
      query.limit = pageSize;
      return { ...query };
    });
  };
  const goGoods = (goods_id) => {
    // history.push(`/merchandise/${goods_id}`);
    window.open(`/merchandise/${goods_id}`);
  };
  const chooseClass = (class_id, index) => {
    if (class_id) {
      setActive1(index);
      setQuery(() => {
        query.store_class_id = class_id;
        query.page = 1;
        return { ...query };
      });
    } else {
      setActive1(-1);
      setQuery(() => {
        query.store_class_id = "";
        query.page = 1;
        return { ...query };
      });
    }
    // document.body.scrollTop = 660;
    // document.documentElement.scrollTop = 660;
  };
  const chooseImages = (index, i) => {
    setImages(() => {
      images[index] = i;
      return [...images];
    });
  };
  // const chooseImages1 = (index, i) => {
  //   setImages1(() => {
  //     images1[index] = i;
  //     return [...images1];
  //   });
  // };
  // const chooseGoods = (index) => {
  //   setIndex(index);
  //   setStyleShow(true);
  // };

  const [allImages, setAllImages] = useState([]);
  const [bigImgShow, setBigImgShow] = useState(false);
  const [bigImgShowIndex, setBigImgShowIndex] = useState(0);

  const [goodsCommonId, setGoodsCommonId] = useState();
  const getGoodsImagesVideoV1 = async (goods_common_id) => {
    setGoodsCommonId(goods_common_id);
    const res = await GoodsImagesVideoV1({ goods_common_id });
    if (!res) return;
    if (res.ResponseStatus) return message.error(res.ResponseMsg);
    setAllImages(res.datas?.images_list || []);
    setBigImgShow(true);
  };
  const imgChoosedLeft = () => {
    setBigImgShowIndex(() => {
      let index = bigImgShowIndex;
      if (index < allImages?.[0]?.goods_images?.length - 1) {
        index = index + 1;
      }
      return index;
    });
  };
  const imgChoosedRight = () => {
    if (bigImgShowIndex === 0) return;
    setBigImgShowIndex(bigImgShowIndex - 1);
  };

  const getImageInfo = () => {
    setBigImgInfoShow(true);
  };

  const [bigImgInfoShow, setBigImgInfoShow] = useState(false);
  const [bigImgInfoShowIndex, setBigImgInfoShowIndex] = useState(0);
  const bigImgInfoChoosedLeft = () => {
    setBigImgInfoShowIndex(() => {
      let index = bigImgInfoShowIndex;
      if (index < allImages?.[allImages + 1]?.goods_images?.length - 1) {
        index = index + 1;
      }
      return index;
    });
  };
  const bigImgInfoChoosedRight = () => {
    if (bigImgInfoShowIndex === 0) return;
    setBigImgInfoShowIndex(bigImgInfoShowIndex - 1);
  };

  const downLoadImg = (imgSrc, name) => {
    AddGoodsDownload({
      type: 1,
      goods_common_id: goodsCommonId,
      browser: window.visitorId,
    });
    const src = imgSrc + `?t=${new Date().getTime()}`;
    fetch(src).then((res) => {
      res.blob().then((myBlob) => {
        const href = URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        a.href = href;
        a.download = name || "zyt_img_" + +new Date();
        a.click();
        a.remove();
      });
    });
  };
  const copyLoadImg = (imgSrc) => {
    AddGoodsDownload({
      type: 1,
      goods_common_id: goodsCommonId,
      browser: window.visitorId,
    });
    const src = imgSrc + `?t=${new Date().getTime()}`;

    var canvas = document.createElement("canvas"); // 创建一个画板
    const image = new Image();
    image.src = src;
    image.setAttribute("crossOrigin", "Anonymous"); //可能会有跨越问题
    image.onload = () => {
      // img加载完成事件
      canvas.width = image.width; //设置画板宽度
      canvas.height = image.height; //设置画板高度
      canvas.getContext("2d").drawImage(image, 0, 0); //加载img到画板
      let url = canvas.toDataURL("image/png"); // 转换图片为dataURL，格式为png
      fetch(url).then((res) => {
        res.blob().then(async (blob) => {
          // console.log("blob.type", blob.type);
          if (!navigator?.clipboard?.write)
            return message.error(
              "此功能只有最新版本将浏览器可以使用！，请将浏览器升级到最新版本！"
            );
          await navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
              "image/png": blob,
            }),
          ]);
          message.success("复制成功");
        });
      });
    };
  };
  // const chooseGoods1 = (index) => {
  //   setIndex1(index);
  //   setStyleShow1(true);
  // };
  const onMouseEnter = () => {
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    swiper.autoplay.start();
  };
  return (
    <div>
      {isIp === 2 ? (
        <div className="ip-modal">
          {isSetIp ? (
            <div className="ip-form-modal">
              <div className="mbottom10">设置IP</div>
              <TextArea
                value={ipText}
                onChange={(e) => {
                  setIpText(e.target.value);
                }}
                rows={12}
                placeholder="请输入"
              />
              <Button size="large" onClick={setIp}>
                提交
              </Button>
            </div>
          ) : (
            <span style={{ lineHeight: 10 }}>IP限制</span>
          )}
        </div>
      ) : (
        <div className="merchant">
          <Nav data={data} member={member}></Nav>
          {global.private ? (
            <PrivateHeader
              store_id={store_id}
              query={query}
              setQuery={setQuery}
              data1={data}
            ></PrivateHeader>
          ) : (
            <Header1
              store_id={store_id}
              query={query}
              setQuery={setQuery}
              data1={data}
            ></Header1>
          )}

          {global.private ? (
            <PrivateTop
              setQuery={setQuery}
              query={query}
              setActive1={setActive1}
              setActive2={setActive2}
            />
          ) : (
            <div className="pp-narBar-Wr">
              <div className="navBarCon container">
                <ul id="nav">
                  <li className="current_page_item focus">
                    <a href={history.location.pathname}>店铺首页</a>
                    <i></i>
                  </li>
                  <li className="current_page_item page_item">
                    <i className="_fff">
                      全部商品<span className="navbarDropDown"></span>
                    </i>
                    <ul className="children">
                      <li
                        className={
                          active1 === -1 ? "owt pointer active" : "owt pointer"
                        }
                        onClick={() => {
                          chooseClass("");
                        }}
                      >
                        全部
                      </li>
                      {store_class.map((item, index) => (
                        <li
                          className={
                            active1 === index
                              ? "owt pointer active"
                              : "owt pointer"
                          }
                          key={index}
                          onClick={() => {
                            chooseClass(item.class_id, index);
                          }}
                        >
                          {item.class_name}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="container prela">
            {/* <RightAffix data={data} member={member}></RightAffix> */}
            {/* <RightAffix
          data={data}
          member={member}
          setBagShow={setBagShow}
          bagShow={bagShow}
          allCheck={allCheck}
          length={
            checkType === "1"
              ? searchGoods?.filter((k) => k.checked)?.length
              : checkAll
              ? total -
                searchGoods.length +
                searchGoods?.filter((k) => k.checked).length
              : 0
          }
          goods={searchGoods?.filter((k) => k.checked)}
          total={checkType === "1" ? searchGoods.length : total}
        ></RightAffix> */}

            <div className="mtop20">
              <div className="imgs">
                {store_info.shop_move &&
                store_info.shop_move.length > 0 &&
                store_info.shop_move[0].img ? (
                  store_info.shop_move.map((k, i) => (
                    <img
                      className="pointer"
                      src={k.img}
                      alt=""
                      key={i}
                      onClick={() => {
                        window.open(k.url);
                      }}
                    />
                  ))
                ) : (
                  <div className="imgs_default">
                    <div>
                      <img className="img" src={store_info.store_logo} alt="" />
                    </div>
                    <div className="info">
                      {store_info.store_mobile ? (
                        <div>电话: {store_info.store_mobile}</div>
                      ) : null}
                      {store_info.address ? (
                        <div>地址: {store_info.address}</div>
                      ) : null}
                    </div>
                    <div className="code">
                      <Images
                        preview={false}
                        height={140}
                        width={140}
                        src={code}
                      ></Images>
                      <div className="icon">
                        <img
                          preview={false}
                          style={{ width: 18, height: 18, objectFit: "cover" }}
                          src={store_info.store_avatar}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => {
                  onMouseEnter();
                }}
                onMouseLeave={() => {
                  onMouseLeave();
                }}
              >
                <Swiper
                  slidesPerView={5}
                  spaceBetween={12}
                  loop
                  loopAdditionalSlides={5}
                  loopedSlides={5}
                  speed={3000}
                  autoplay={{
                    delay: 0,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                  }}
                  freeMode={true}
                  className="shops2 flex"
                  onSwiper={setSwiper}
                >
                  {goods_list.map((item, i) => (
                    <SwiperSlide key={i}>
                      <div className="shop" key={index}>
                        {/* {bagShow?(
                        <div className="check">
                          <Checkbox checked={item.checked} onChange={
                            e => {
                              setSearchGoods(()=>{
                                searchGoods[index].checked = e.target.checked
                                return [...searchGoods]
                              })
                            }
                          }></Checkbox>
                        </div>
                      ):null} */}
                        <div
                          className={`pointer ${item.is_main ? "is_main" : ""}`}
                          onClick={() => {
                            goGoods(item.goods_id);
                          }}
                        >
                          {images1[index] > -1 ? (
                            <Images
                              preview={false}
                              src={item.goods_images[images1[index]].images}
                              width={265}
                              height={265}
                            ></Images>
                          ) : (
                            <Images
                              preview={false}
                              src={item.images}
                              width={265}
                              height={265}
                            ></Images>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>

            <div className="flex">
              <StoreClass
                classes={store_class}
                setQuery={setQuery}
                query={query}
                active1={active1}
                setActive1={setActive1}
                active2={active2}
                setActive2={setActive2}
              ></StoreClass>
              <div
                className="flex1"
                style={{ minHeight: "calc(100vh - 24px)" }}
              >
                <SearchTabStore
                  query={query}
                  setQuery={setQuery}
                  total={total}
                  color={color}
                ></SearchTabStore>
                <Spin spinning={spinning}>
                  {searchGoods.length === 0 && !spinning ? (
                    <EmptyGoods query={query}></EmptyGoods>
                  ) : color === 1 ? (
                    <div className="shops flex-w">
                      {searchGoods.map((item, index) => (
                        <div className="shop" key={index}>
                          {item.is_main ? (
                            <div
                              className="shop_tag_sole"
                              style={{
                                left: 10,
                                right: 210,
                                textAlign: "center",
                              }}
                            >
                              主打款
                            </div>
                          ) : null}

                          {item.is_sole ? (
                            <div className="shop_tag_sole">独家图片</div>
                          ) : null}
                          <div
                            className={`pic pointer ${
                              item.is_video ? "bgvideo" : ""
                            }`}
                            onClick={() => {
                              goGoods(item.goods_id);
                            }}
                          >
                            {item.goods_images.length > 0 ? (
                              item.goods_images.map((k1, i1) =>
                                k1.color_id === item.color_id ? (
                                  <Images
                                    key={i1}
                                    preview={false}
                                    src={k1.images}
                                    width={275}
                                    height={275}
                                  ></Images>
                                ) : null
                              )
                            ) : (
                              <Images
                                preview={false}
                                src={item.goods_image}
                                width={275}
                                height={275}
                              ></Images>
                            )}
                          </div>
                          <div className="desc">
                            <div className="rowPri flex-b">
                              <div className="price">￥{item.goods_price}</div>
                              <div className="fz12 _999">
                                {item.detail_goods_click}浏览
                              </div>
                            </div>
                            <a
                              target="blank"
                              href={`/merchandise/${item.goods_id}`}
                              style={{ display: "block" }}
                              className="dsrs owt2 fz12 pointer _666"
                            >
                              <span className="owt2">
                                {item.goods_name
                                  .split(query.keywords)
                                  .map((k1, i1) => (
                                    <span key={i1}>
                                      {i1 > 0 ? (
                                        <span className="_ff2d4f">
                                          {query.keywords}
                                        </span>
                                      ) : null}
                                      {k1}
                                    </span>
                                  ))}
                              </span>
                            </a>
                            <div className="flex-center flex-b">
                              <div className="mtop6">
                                <span
                                  className={`arr-com dq-area1 mbottom6 ${
                                    item.is_df === 1 ? "arr-com1" : ""
                                  }`}
                                >
                                  {item.is_df === 1
                                    ? "代发"
                                    : item.is_df === 2
                                    ? "商家发货"
                                    : "不发"}
                                </span>
                                {item.is_x ? (
                                  <span className="arr-com arr-com3 dq-area1 mbottom2">
                                    新品
                                  </span>
                                ) : null}
                                {item.is_sole ? (
                                  <span className="arr-com arr-com5 dq-area1 mbottom2">
                                    独家图片
                                  </span>
                                ) : null}
                                <span className="arr-com dq-area1 mbottom6">
                                  {item.ys_count}色{item.cm_count}码
                                </span>
                                {item.is_bfs ? (
                                  <span className="arr-com arr-com2 dq-area1 mbottom2">
                                    买家秀
                                  </span>
                                ) : null}
                              </div>
                              <div className="_999">{item.create_time}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="shops1 shopsd flex-w">
                      {searchGoods.map((item, index) => (
                        <div className="shop" key={index}>
                          <div
                            className={`pic pointer ${
                              item.is_video ? "bgvideo" : ""
                            }`}
                            onClick={() => {
                              goGoods(item.goods_id);
                            }}
                          >
                            {images[index] > -1 ? (
                              <Images
                                preview={false}
                                src={item.goods_images[images[index]].images}
                                width={275}
                                height={275}
                              ></Images>
                            ) : (
                              <Images
                                preview={false}
                                src={item.goods_image}
                                width={275}
                                height={275}
                              ></Images>
                            )}
                          </div>
                          <div className="desc">
                            <div className="images">
                              {item.goods_images.length > 0 ? (
                                item.goods_images.map((k1, i1) =>
                                  i1 < 6 ? (
                                    <div
                                      key={i1}
                                      className="mr2 imgd"
                                      onMouseEnter={() => {
                                        chooseImages(index, i1);
                                      }}
                                      style={{
                                        border:
                                          images[index] === i1
                                            ? "1px solid #f0423f"
                                            : "1px solid rgb(228, 228, 228)",
                                        marginRight: i1 === 5 ? "0px" : "",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <Images
                                        width={30}
                                        height={30}
                                        preview={false}
                                        src={k1.images}
                                        key={i1}
                                      ></Images>
                                    </div>
                                  ) : null
                                )
                              ) : (
                                <div
                                  className="mr2 imgd"
                                  onMouseEnter={() => {
                                    chooseImages(index, -2);
                                  }}
                                  style={{
                                    border:
                                      images[index] === -2
                                        ? "1px solid #f0423f"
                                        : "1px solid rgb(228, 228, 228)",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Images
                                    width={30}
                                    height={30}
                                    preview={false}
                                    src={item.goods_image}
                                  ></Images>
                                </div>
                              )}
                              <div
                                className="pointer"
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                }}
                                onClick={() => {
                                  getGoodsImagesVideoV1(item.goods_common_id);
                                }}
                                title="查看全部花型"
                              >
                                <Images
                                  width={34}
                                  height={34}
                                  preview={false}
                                  src={quick_look_img_icon}
                                ></Images>
                              </div>
                            </div>
                            <div className="rowPri flex-b">
                              <div className="price">
                                {item?.goods_price < 9999 ? "¥" : null}
                                {store_info.ac_id === 9 && item.goods_price >= 9999
                                  ? "价格面议"
                                  : item.goods_price}
                              </div>
                            </div>
                            <div
                              className="dsrs owt2 fz12 pointer"
                              onClick={() => {
                                goGoods(item.goods_id);
                              }}
                            >
                              {/* {item.goods_name} */}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: (() => {
                                    let text = item.goods_name;
                                    (query.keywords?.split(" ") || []).forEach(
                                      (k) => {
                                        text = text.replace(
                                          eval(`/${k}/g`), // eslint-disable-line
                                          `<span style='color: #ff2d4f'>${k}</span>`
                                        );
                                      }
                                    );
                                    return text;
                                  })(),
                                }}
                              ></span>
                            </div>
                            <div className="icon-has-more fz12 mtop4 mbottom10 flex-center flex-b">
                              <a
                                href={`/merchant/${item.store_id}`}
                                className="icon pointer _666"
                              >
                                {item.store_name}
                              </a>
                            </div>
                            <div className="flex-center flex-b">
                              <div>
                                <span
                                  className={`arr-com dq-area1 mbottom6 ${
                                    item.is_df === 1 ? "arr-com1" : ""
                                  }`}
                                >
                                  {item.is_df === 1
                                    ? "代发"
                                    : item.is_df === 2
                                    ? "商家发货"
                                    : "不发"}
                                </span>
                                {item.is_x ? (
                                  <span className="arr-com arr-com3 dq-area1 mbottom2">
                                    新品
                                  </span>
                                ) : null}
                                <span className="arr-com dq-area1 mbottom6">
                                  {item.ys_count}色{item.cm_count}码
                                </span>
                                {item.is_bfs ? (
                                  <span className="arr-com arr-com2 dq-area1 mbottom2">
                                    买家秀
                                  </span>
                                ) : null}
                              </div>
                              <div className="_999">{item.create_time}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Spin>
                <div
                  className="flex-centerY mtop10"
                  style={{
                    display: searchGoods.length === 0 ? "none" : "flex",
                  }}
                >
                  <Pagination
                    current={query.page}
                    pageSize={query.limit}
                    total={total}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <Bottom data={data} member={member}></Bottom>

          <Modal
            visible={styleShow}
            destroyOnClose
            title="全花型浏览"
            okText="确定"
            cancelText="取消"
            centered={true}
            footer={null}
            width={840}
            onCancel={() => {
              setStyleShow(false);
            }}
            onOk={() => {
              // delHandle();
            }}
            className="modal"
          >
            <div className="goodsInfo">
              <div className="title flex-b">
                <div className="left">
                  <div className="store_name fw700 fz16">
                    {searchGoods[index] && searchGoods[index].store_name}
                  </div>
                  <div className="goods_name fz12">
                    {searchGoods[index] && searchGoods[index].goods_name}
                  </div>
                  <div className="goods_price _ff2d4f">
                    ￥{searchGoods[index] && searchGoods[index].goods_price}
                  </div>
                </div>
                <div className="right flex-b flex-center">
                  <div>
                    <div className="_ff2d4f fz14">1</div>
                    <div>总浏览量</div>
                  </div>
                  <div>
                    <div className="_ff2d4f fz14">1</div>
                    <div>下载/上传数</div>
                  </div>
                  <div>
                    <div className="_ff2d4f fz14">1</div>
                    <div>总销量</div>
                  </div>
                </div>
              </div>
              <div className="images flex-w mtop10">
                {searchGoods[index] &&
                  searchGoods[index].goods_images.map((k1, i1) => (
                    <a
                      style={{ display: "block" }}
                      target="blank"
                      href={`/merchandise/${k1.goods_id}`}
                      className="mtop6"
                      key={i1}
                    >
                      {/* <Image
                    width={120}
                    height={120}
                    preview={false}
                    src={k1.images}
                    key={i1}
                  ></Image> */}
                      <img
                        style={{
                          width: "120px",
                          height: "120px",
                          objectFit: "cover",
                        }}
                        src={k1.images}
                        key={i1}
                        alt=""
                      />
                    </a>
                  ))}
              </div>
              <div
                className="submit"
                onClick={() => {
                  history.push(
                    `/merchandise/${
                      searchGoods[index] && searchGoods[index].goods_id
                    }`
                  );
                }}
              >
                查看商品详情
              </div>
            </div>
          </Modal>
          <Modal
            visible={styleShow1}
            destroyOnClose
            title="全花型浏览"
            okText="确定"
            cancelText="取消"
            centered={true}
            footer={null}
            width={840}
            onCancel={() => {
              setStyleShow1(false);
            }}
            onOk={() => {
              // delHandle();
            }}
            className="modal"
          >
            <div className="goodsInfo">
              <div className="title flex-b">
                <div className="left">
                  <div className="store_name fw700 fz16">{store_name}</div>
                  <div className="goods_name fz12">
                    {goods_list[index1] && goods_list[index1].goods_name}
                  </div>
                  <div className="goods_price _ff2d4f">
                    ￥{goods_list[index1] && goods_list[index1].goods_price}
                  </div>
                </div>
                <div className="right flex-b flex-center">
                  <div>
                    <div className="_ff2d4f fz14">1</div>
                    <div>总浏览量</div>
                  </div>
                  <div>
                    <div className="_ff2d4f fz14">1</div>
                    <div>下载/上传数</div>
                  </div>
                  <div>
                    <div className="_ff2d4f fz14">1</div>
                    <div>总销量</div>
                  </div>
                </div>
              </div>
              <div className="images flex-w mtop10">
                {goods_list?.[index1]?.goods_images?.map((k1, i1) => (
                  <a
                    style={{ display: "block" }}
                    target="blank"
                    href={`/merchandise/${k1.goods_id}`}
                    className="mtop6"
                    key={i1}
                  >
                    {/* <Image
                    width={120}
                    height={120}
                    preview={false}
                    src={k1.images}
                    key={i1}
                  ></Image> */}
                    <img
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                      }}
                      src={k1.images}
                      key={i1}
                      alt=""
                    />
                  </a>
                ))}
              </div>
              <div
                className="submit"
                onClick={() => {
                  history.push(
                    `/merchandise/${
                      goods_list[index1] && goods_list[index1].goods_id
                    }`
                  );
                }}
              >
                查看商品详情
              </div>
            </div>
          </Modal>

          <Modal
            visible={bigImgShow}
            destroyOnClose
            title="查看花型大图"
            okText="确定"
            cancelText="取消"
            footer={null}
            width={1400}
            closeIcon={<CloseOutlined className="fz24" />}
            onCancel={() => {
              setBigImgShow(false);
            }}
            className="modal"
            style={{ top: 10 }}
          >
            <div
              className="goodsInfo"
              style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                paddingTop: 0,
              }}
            >
              <div className="mtop10 flex-start">
                <div className="bigImages textC">
                  <ImageAnt
                    preview={false}
                    width={620}
                    height={620}
                    src={allImages?.[0]?.goods_images?.[bigImgShowIndex]}
                  ></ImageAnt>
                  <div
                    className="arrow arrow-left"
                    onClick={() => {
                      imgChoosedLeft();
                    }}
                  >
                    <LeftOutlined />
                  </div>
                  <div
                    className="arrow arrow-right"
                    onClick={() => {
                      imgChoosedRight();
                    }}
                  >
                    <RightOutlined />
                  </div>
                </div>
                <div
                  className="flex-w pointer"
                  style={{
                    maxHeight: 620,
                    overflowY: "auto",
                  }}
                >
                  {allImages?.[0]?.goods_images?.map?.((item, index) => (
                    <div key={index}>
                      <div className="textC">
                        <div
                          style={{
                            borderRadius: 2,
                            backgroundColor: "#f6f6f6",
                            margin: "0 0 10px 10px",
                            overflow: "hidden",
                            border:
                              bigImgShowIndex === index
                                ? "2px solid #dd5148"
                                : "2px solid #ccc",
                          }}
                          className="copy-img-f"
                          onClick={() => {
                            getImageInfo();
                          }}
                          onMouseOver={() => {
                            setBigImgShowIndex(index);
                          }}
                        >
                          <img
                            style={{
                              width: 120,
                              height: 120,
                            }}
                            src={
                              item +
                              "?x-oss-process=image/resize,m_lfit,h_240,w_240"
                            }
                            alt=""
                            onSelect={() => {
                              return false;
                            }}
                          />
                          <div
                            className="copy-img-icon"
                            style={{
                              top: 98,
                              borderRadius: 0,
                            }}
                            title="复制图片"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyLoadImg(item);
                            }}
                          >
                            <img
                              src={copyImg}
                              style={{ wdith: 12, height: 12 }}
                              alt=""
                            />
                          </div>
                          <div
                            className="copy-img-icon"
                            style={{
                              top: 98,
                              right: 22,
                              color: "#111",
                              borderRight: "1px solid #999",
                            }}
                            title="下载图片"
                            onClick={(e) => {
                              e.stopPropagation();
                              downLoadImg(
                                item,
                                allImages?.[index + 1]?.color_name
                              );
                            }}
                          >
                            <VerticalAlignBottomOutlined />
                          </div>

                          <div
                            className="hover-red"
                            style={{ width: 120 }}
                            onClick={() => {
                              getImageInfo();
                            }}
                          >
                            {allImages?.[index + 1]?.color_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            visible={bigImgInfoShow}
            destroyOnClose
            title="查看花型细节图"
            okText="确定"
            cancelText="取消"
            footer={null}
            width={1400}
            closeIcon={<CloseOutlined className="fz24" />}
            onCancel={() => {
              setBigImgInfoShow(false);
            }}
            className="modal"
            style={{ top: 10 }}
          >
            <div
              className="goodsInfo"
              style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                paddingTop: 0,
              }}
            >
              <div className="mtop10 flex-start">
                <div className="bigImages textC">
                  <div
                    className="textL ml10 fw700 mbottom10 fz16"
                    style={{ width: "100%" }}
                  >
                    <span>花型主图</span>
                  </div>
                  <ImageAnt
                    preview={false}
                    width={620}
                    height={620}
                    src={
                      allImages?.[bigImgShowIndex + 1]?.goods_images?.[
                        bigImgInfoShowIndex
                      ]
                    }
                  ></ImageAnt>
                  <div
                    className="arrow arrow-left"
                    onClick={() => {
                      bigImgInfoChoosedLeft();
                    }}
                  >
                    <LeftOutlined />
                  </div>
                  <div
                    className="arrow arrow-right"
                    onClick={() => {
                      bigImgInfoChoosedRight();
                    }}
                  >
                    <RightOutlined />
                  </div>
                </div>
                <div className="flex-w pointer flex1">
                  <div
                    className="textL ml10 fw700 mbottom10 fz16"
                    style={{ width: "100%" }}
                  >
                    <span
                      className="pointer hover-red"
                      onClick={() => {
                        setBigImgShow(true);
                        setBigImgInfoShow(false);
                      }}
                    >
                      花型主图
                    </span>
                    <span className="ml4 mr4">&gt;</span>
                    <span>{allImages?.[bigImgShowIndex + 1]?.color_name}</span>
                  </div>
                  <div
                    className="flex-w"
                    style={{ width: "100%", maxHeight: 620, overflowY: "auto" }}
                  >
                    {allImages?.[bigImgShowIndex + 1]?.goods_images?.map?.(
                      (item, index) => (
                        <div
                          key={index}
                          className="copy-img-f"
                          style={{
                            border:
                              bigImgInfoShowIndex === index
                                ? "2px solid #dd5148"
                                : "2px solid #ccc",
                          }}
                          onMouseOver={() => {
                            setBigImgInfoShowIndex(index);
                          }}
                        >
                          <img
                            style={{ width: 120, height: 120 }}
                            src={
                              item +
                              "?x-oss-process=image/resize,m_lfit,h_240,w_240"
                            }
                            alt=""
                          />
                          <div
                            className="copy-img-icon"
                            title="复制图片"
                            style={{
                              borderRadius: 0,
                            }}
                            onClick={() => {
                              copyLoadImg(item);
                            }}
                          >
                            <img
                              src={copyImg}
                              style={{ wdith: 12, height: 12 }}
                              alt=""
                            />
                          </div>
                          <div
                            className="copy-img-icon"
                            style={{
                              right: 22,
                              color: "#111",
                              borderRight: "1px solid #999",
                            }}
                            title="下载图片"
                            onClick={(e) => {
                              e.stopPropagation();
                              downLoadImg(
                                item,
                                allImages?.[bigImgShowIndex + 1]?.color_name +
                                  "_" +
                                  (index + 1)
                              );
                            }}
                          >
                            <VerticalAlignBottomOutlined />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default Merchant;
