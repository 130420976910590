import http from "utils/request";
import payhttp from "utils/payrequest";

// topBanner
export const StoreInfo = (params = {}) => {
  return http.get("/api/wap/store_info", { params });
}; 
// topBanner
export const storeClass = (params = {}) => {
  return http.get("/api/wap/store_class", { params });
}; 
export const StoreGoodsList = (params = {}) => {
  return http.get("/api/wap/store_goods_list", { params });
}; 
export const GetStore = (params = {}) => {
  return http.get("/api/wap/store", { params });
}; 
export const GetStoreSubject = (params = {}) => {
  return http.get("/api/wap/get_store_subject", { params });
}; 
// 收藏
export const GetFavorites = (params = {}) => {
  return http.get("/api/member/get_favorites", { params });
}; 
export const AddFavorites = (params = {}) => {
  return http.post("/api/member/add_favorites", params );
}; 
export const DelFavorites = (params = {}) => {
  return http.post("/api/member/del_favorites", params);
}; 
// 看了又看
export const GoodsLook = (params = {}) => {
  return http.get("/api/wap/goods_look", { params });
};
// 店铺新品
export const StoreGoodsNew = (params = {}) => {
  return http.get("/api/wap/store_goods_new", { params });
};
// 店铺二维码
export const StoreCodeMall = (params = {}) => {
  return payhttp.get("/api/wap/store_code_mall", { params });
};
// 疫情发货商家
export const StoreSend = (params = {}) => {
  return http.get("/api/wap/store_send", { params });
}; 
// 店铺导航
export const StoreNavigation = (params = {}) => {
  return http.get("/api/wap/store_navigation", { params });
}; 
// 店铺云盘
export const GodosZip = (params = {}) => {
  return http.get("/api/wap/godos_zip", { params });
}; 



