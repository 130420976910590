import { useState, useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import "./index.less";

import { GoodsClass, GoodsClassClick } from "api/home";
import { useHistory } from "react-router";
import { GetNewRecommendConfig } from "api/home";

const ClassBox = (props) => {
  const { getClasses, classStart } = props;
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [active, setActive] = useState(0);

  // 获取分类
  const [data, setData] = useState([]);
  useEffect(() => {
    const getGoodsClass = async () => {
      const res = await GoodsClass({ index_type: 1 });
      const arr = res?.datas || [];
      setData(arr);
      getClasses?.(arr);
    };
    if(classStart || getClasses)getGoodsClass();
  }, [classStart, getClasses]);

  // 获取广告
  const [dataMap, setDataMap] = useState(new Map());
  const getRecommendConfig = async (keys) => {
    if (dataMap?.has?.(keys)) return;
    const res = await GetNewRecommendConfig({ keys });
    if (!res) return;
    if (res.ResponseStatus) return;
    const arr = res?.datas || [];
    const map = new Map(dataMap);
    arr.forEach((k) => {
      if (k.key !== "other") {
        map.set(k.key, k);
      }
    });
    setDataMap(map);
  };
  const dataFn = (gc_id) => {
    const arr = (dataMap?.get?.(`class_${gc_id}`) || {})?.template_common || [];
    return arr;
  };

  // 弹窗事件
  const onMouseEnter = (k, i) => {
    setIsShow(true);
    setActive(i);
    const key = `class_${k.gc_id}`;
    getRecommendConfig(key);
  };
  const onMouseLeave = () => {
    setIsShow(false);
  };

  // 跳转
  const goSearch = async (class_name, arr) => {
    const gc_ids = arr?.join?.(",");
    const str = `/search?active=0&gc_ids=${gc_ids}`;
    if (history?.location?.pathname === "/search") {
      history.replace(str);
    } else {
      window.open(str);
    }

    setIsShow(false);
    GoodsClassClick({
      class_name,
      gc_id_1: arr?.[0]?.toString?.() || "0",
      gc_id_2: arr?.[1]?.toString?.() || "0",
      gc_id_3: arr?.[2]?.toString?.() || "0",
    });
  };

  return (
    <div
      className="new_home-class-box"
      onMouseEnter={() => {
        setIsShow(true);
      }}
      onMouseLeave={onMouseLeave}
    >
      <ul>
        {data.map((k, i) => (
          <li
            key={i}
            className="info"
            onMouseEnter={() => {
              onMouseEnter(k, i);
            }}
          >
            <div className="title flex-b">
              <div className="flex-center">
                <span
                  className="fz14 _111 fw700 pointer hover-red"
                  onClick={() => {
                    goSearch(k.gc_name, [k.gc_id]);
                  }}
                >
                  {k.gc_name}
                </span>
              </div>
              <div>
                <RightOutlined className="fz10 _ccc" />
              </div>
            </div>
            <ol className="flex-w" style={{ height: 14, overflow: "hidden" }}>
              {k?.subs?.map?.((item, index) => (
                <li
                  key={index}
                  className="pointer fz12"
                  onClick={() => {
                    goSearch(item.gc_name, [k.gc_id, item.gc_id]);
                  }}
                >
                  {item.gc_name}
                </li>
              ))}
            </ol>
          </li>
        ))}
        {/* <li className="info">
          <div className="title flex-b">
            <div className="flex-center">
              <span
                className="fz14 _111 fw700 pointer hover-red"
                onClick={() => {
                  window.open("/services-market?&ni=6");
                }}
              >
                服务市场
              </span>
            </div>
            <div>
              <RightOutlined className="fz10 _ccc" />
            </div>
          </div>
          <ol className="flex-w" style={{ height: 14, overflow: "hidden" }}>
            <li
              className="pointer fz12"
              onClick={() => {
                window.open("/services-market?&ni=6");
              }}
            >
              服务市场
            </li>
          </ol>
        </li> */}
      </ul>
      <div
        className="new_home-good-expansion"
        onMouseEnter={() => {
          setIsShow(true);
        }}
        style={{
          display: isShow ? "block" : "none",
        }}
      >
        <div className="ul">
          {!["单品", "凉席", "蚊帐"]?.includes?.(data?.[active]?.gc_name) ? (
            <ul>
              {data?.[active]?.subs?.map?.((item, index) =>
                item?.gc_name ? (
                  <li key={index}>
                    <div className="title flex-b">
                      <div>
                        <span
                          className="fz14 fw700 pointer hover-red"
                          onClick={() => {
                            goSearch(item.gc_name, [
                              data[active].gc_id,
                              item.gc_id,
                            ]);
                          }}
                        >
                          {item.gc_name}
                        </span>
                      </div>
                    </div>
                    <ol className="flex-w">
                      {item?.subs?.map?.((sitem, sindex) => (
                        <li
                          key={sindex}
                          className="pointer"
                          onClick={() => {
                            goSearch(sitem.gc_name, [
                              data[active].gc_id,
                              item.gc_id,
                              sitem.gc_id,
                            ]);
                          }}
                        >
                          {sitem.gc_name}
                        </li>
                      ))}
                    </ol>
                  </li>
                ) : null
              )}
            </ul>
          ) : (
            <ul className="flex-center flex-w">
              {data?.[active]?.subs?.map?.((item, index) =>
                item?.gc_name ? (
                  <span
                    key={index}
                    className="fz14 pointer hover-red fw700 mbottom20"
                    style={{ marginRight: 32 }}
                    onClick={() => {
                      goSearch(item.gc_name, [data[active].gc_id, item.gc_id]);
                    }}
                  >
                    {item.gc_name}
                  </span>
                ) : null
              )}
            </ul>
          )}

          <div className="new-goods">
            <div className="new-goods-title">新品推荐</div>
            <div className="flex">
              {dataFn(data?.[active]?.gc_id)?.map?.((k, i) => (
                <div
                  className="new-goods-item"
                  style={{ width: "50%" }}
                  key={i}
                >
                  <div className="item-top">
                    <div className="item-top-shop-name owt">{k.store_name}</div>
                    <div className="item-top-good-name owt">
                      {k.template_name}
                    </div>
                  </div>
                  <div className="item-goods">
                    {k?.value?.map?.((k1, i1) => (
                      <img
                        key={i1}
                        className="goods"
                        src={k1.template_img}
                        alt=""
                        onClick={() => {
                          window.open(k1.template_url);
                        }}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClassBox;
