import { useState, useEffect } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

// less
import "./index.less";

// api
import { Navigation, GoodsSkuCount } from "api/home";

// module
import ClassBox from "pages/components/ClassBox";

// img
import page_nav_class_icon from "assets/icon/page_nav_class_icon.png";
import page_nav_class_icon1 from "assets/icon/page_nav_class_icon1.png";

const PageNav = (props) => {
  const { is_home, getClasses, is_blcok } = props;
  const history = useHistory();
  const [data, setData] = useState([]);
  const [skuCount, setSkuCount] = useState();

  useEffect(() => {
    const goodsSkuCount = async () => {
      const res = await GoodsSkuCount({});
      if (!res) return;
      if (res.ResponseStatus) return;
      setSkuCount(res.datas || 0);
      setTimeout(() => {
        goodsSkuCount();
      }, 300 * 1000);
    };

    const getNavigation = async () => {
      const res = await Navigation();
      if (res.ResponseStatus !== 0) return;
      setData(res?.datas || []);
      goodsSkuCount();
    };
    getNavigation();
  }, []);

  const [classStart, setClassStart] = useState(false);

  // 更多逻辑
  const [noneIndex, setNoneIndex] = useState(0);
  useEffect(() => {
    const width = is_blcok ? 1400 : 1200;
    const setInitWitdh = () => {
      const parentsLeft = document.querySelector(".page-navs")?.offsetLeft;
      const doms = document.querySelectorAll(".page-nav");
      let bool = false;
      doms?.forEach?.((dom) => {
        const domRight = dom.offsetLeft + dom.offsetWidth - parentsLeft;
        if (domRight > width) bool = true;
      });
      if (bool) {
        let index;
        doms.forEach((dom, i) => {
          const domRight = dom.offsetLeft + dom.offsetWidth - parentsLeft;
          if (domRight > width - 74 || domRight < 0) {
            dom.style.display = "none";
            if (!index || i < index) index = i;
          }
        });
        index = is_home ? index - 3 : index - 4;
        setNoneIndex(index);
      }
    };
    if (data?.length) {
      setTimeout(() => {
        setInitWitdh()
      }, 20);
      setTimeout(() => {
        setInitWitdh()
      }, 60);
      setTimeout(() => {
        setInitWitdh()
      }, 80);
      setTimeout(() => {
        setInitWitdh()
      }, 120);
      setTimeout(() => {
        setInitWitdh()
      }, 200);
      setTimeout(() => {
        setInitWitdh()
      }, 600);
    }
  }, [data?.length, is_home, is_blcok]);

  return (
    <div
      className="new_home-page-nav"
      style={{ borderBottomColor: is_home ? "#fff" : "E5E5E5" }}
    >
      <div className="container">
        <ul className="page-navs">
          {is_home ? null : (
            <li
              className="page-nav plate-class"
              onMouseEnter={() => {
                setClassStart(true);
              }}
            >
              <img
                src={is_blcok ? page_nav_class_icon1 : page_nav_class_icon}
                alt=""
              />
              <div className="class_box">
                <ClassBox getClasses={getClasses} classStart={classStart} />
              </div>
            </li>
          )}

          <li
            className={`page-nav ${is_home ? "other-active" : ""}`}
            onClick={() => {
              window.open("/", 0);
            }}
          >
            <span>首页</span>
          </li>
          <li
            className={`page-nav fw700 ${
              history.location?.pathname === "/new_day" ? "active" : ""
            }`}
            style={{ color: "var(--main-color)" }}
            onClick={() => {
              window.open("/new_day");
            }}
          >
            今日新品
            <div className="count">
              <span>{+skuCount === 0 ? "上新中" : +skuCount + "款"}</span>
            </div>
          </li>
          {data.map((k, i) => (
            <li
              key={i}
              className={`page-nav skip-hot ${
                history.location?.pathname === "/" + k.key ? "active" : ""
              }`}
              style={{
                color: k.color || "",
                fontWeight: k.is_weight ? 700 : "",
              }}
              onClick={() => {
                window.open(k.url);
              }}
            >
              {k.navigation_name}
              {k.icon ? (
                <div className="skip-icon">
                  <img src={k.icon} style={{ height: 22 }} alt="" />
                </div>
              ) : null}
              {k.hot === 1 && !k.icon ? (
                <div className="skip-hot-icon">
                  <span>HOT</span>
                </div>
              ) : null}
              {k.is_new === 1 && !k.icon ? (
                <div className="skip-hot-icon">
                  <span>NEW</span>
                </div>
              ) : null}
            </li>
          ))}
          {noneIndex ? (
            <li className="page-nav-more">
              <span>更多</span>
              <CaretDownOutlined className="fz12" />
              <div className="page-nav-more-extend">
                {data
                  ?.filter?.((k, i) => i > noneIndex)
                  ?.map?.((k, i) => (
                    <div
                      key={i}
                      className="page-nav-more-extend-item"
                      style={{
                        color: k.color || "",
                        fontWeight: k.is_weight ? 700 : "",
                      }}
                      onClick={() => {
                        window.open(k.url);
                      }}
                    >
                      <span>
                        {k.navigation_name}
                        {k.icon ? (
                          <div className="skip-icon">
                            <img src={k.icon} style={{ height: 22 }} alt="" />
                          </div>
                        ) : null}
                        {k.hot === 1 && !k.icon ? (
                          <div className="skip-hot-icon">
                            <span>HOT</span>
                          </div>
                        ) : null}
                        {k.is_new === 1 && !k.icon ? (
                          <div className="skip-hot-icon">
                            <span>NEW</span>
                          </div>
                        ) : null}
                      </span>
                    </div>
                  ))}
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};
export default PageNav;
