import { lazy } from "react";

const Login = lazy(() => import("pages/login"));
const ResetPsd = lazy(() => import("pages/login/ResetPsd"));

const Wealth = lazy(() => import("pages/Wealth"));
const WealthRaffle = lazy(() => import("pages/Wealth/Raffle"));
const WealthInvitation = lazy(() => import("pages/Wealth/Invitation"));

const Register = lazy(() => import("pages/login/register"));
const WxLogin = lazy(() => import("pages/login/wxlogin"));
const NewHome = lazy(() => import("pages/new_home"));
const NewSearch = lazy(() => import("pages/new_search"));
const HomeVideo = lazy(() => import("pages/homevideo/homevideo"));
const Merchandise = lazy(() => import("pages/Merchandise"));
const GoodsRank = lazy(() => import("pages/goodsrank/goodsrank"));
const Merchant = lazy(() => import("pages/merchant/merchant"));
const MerchantGoods = lazy(() => import("pages/merchantGoods/merchantGoods"));
const Special = lazy(() => import("pages/special/special"));
const Subject = lazy(() => import("pages/subject/subject"));
const Fabric = lazy(() => import("pages/subject/fabric"));
const ErpApply = lazy(() => import("pages/erp/apply"));
const Search = lazy(() => import("pages/search/search"));
const OffProducts = lazy(() => import("pages/offProducts/index"));
const StrengthRank = lazy(() => import("pages/StrengthRank/index"));
const OnlineRetailerTool = lazy(() => import("pages/OnlineRetailerTool/index"));

const Cutout = lazy(() => import("pages/Cutout"));
const Sole = lazy(() => import("pages/new_today/is_sole"));
const IsMain = lazy(() => import("pages/new_today/is_main"));
const B = lazy(() => import("pages/new_today/is_b"));
const NewDay = lazy(() => import("pages/new_other_search"));

const SevenDown = lazy(() => import("pages/new_today/seven_down"));
const SoutuHot = lazy(() => import("pages/new_today/soutu_hot"));
const PopularProducts = lazy(() => import("pages/PopularProducts"));

const YJDf = lazy(() => import("pages/yjdf/index"));
const MH = lazy(() => import("pages/mh/index"));
const NH = lazy(() => import("pages/nh/index"));
const Explosion = lazy(() => import("pages/Explosion"));

const SearchImg = lazy(() => import("pages/searchImg/searchImg"));
const YiFang = lazy(() => import("pages/yiFang/yiFang"));
const YiFangInfo = lazy(() => import("pages/yiFang/info/info"));
const SearchAssistant = lazy(() =>
  import("pages/searchassistant/searchassistant")
);
const ServicesMarket = lazy(() => import("pages/ServicesMarket"));
const ServicesMarketinfo = lazy(() => import("pages/ServicesMarket/info"));

const ShopCar = lazy(() => import("pages/shopcar/shopcar"));
const Order = lazy(() => import("pages/order/order"));
const OrderPay = lazy(() => import("pages/orderpay/orderpay"));
const OrderPayOk = lazy(() => import("pages/orderpayok/orderpayok"));
const WxPay = lazy(() => import("pages/wxpay/wxpay"));
const MyOrderList = lazy(() => import("pages/person/myorderlist/myorderlist"));

const Apply = lazy(() => import("pages/person/apply/apply"));
const ApplyStickers = lazy(() => import("pages/person/apply_stickers/apply"));
const ApplicationReimbursement = lazy(() =>
  import("pages/person/application_reimbursement")
);

const CopyAll = lazy(() => import("pages/person/copy/copyall"));
const CopyLog = lazy(() => import("pages/person/copy/copylog"));
const CopyBag = lazy(() => import("pages/person/copybag"));
const Deliver = lazy(() => import("pages/deliver/deliver"));

const PddOrderList = lazy(() =>
  import("pages/person/pddorderlist/pddorderlist")
);
const PddGoodsList = lazy(() =>
  import("pages/person/pddgoodslist/pddgoodslist")
);
const AliGoodsList = lazy(() =>
  import("pages/person/aligoodslist/aligoodslist")
);
const AliGoodsListJC = lazy(() =>
  import("pages/person/aligoodslistjc/aligoodslistjc")
);
const AliGoodsListFZ = lazy(() =>
  import("pages/person/aligoodslistfz/aligoodslistfz")
);

const Authorize = lazy(() => import("pages/person/authorize/authorize"));
const SharedAccount = lazy(() => import("pages/person/SharedAccount"));
const ReturnList = lazy(() => import("pages/person/returnlist/returnlist"));
const ReturnDetail = lazy(() =>
  import("pages/person/returndetail/returndetail")
);
const ApplyRefund = lazy(() => import("pages/person/applyrefund/applyrefund"));
const Address = lazy(() => import("pages/person/address/address"));
const Sender = lazy(() => import("pages/person/sender/sender"));
const Refund = lazy(() => import("pages/person/refund/refund"));
const OrderDetail = lazy(() => import("pages/person/orderdetail/orderdetail"));
const GoodsFav = lazy(() => import("pages/person/goodsfav/goodsfav"));
const StoreFav = lazy(() => import("pages/person/storefav/storefav"));

const DYUpLoad = lazy(() => import("pages/upload/dyupload/upload"));

const AliUpLoad = lazy(() => import("pages/upload/aliupload/upload"));

const KSUpLoad = lazy(() => import("pages/upload/index"));
const TBUpLoad = lazy(() => import("pages/upload/index"));
const TMUpLoad = lazy(() => import("pages/upload/index"));
const PDDUpLoad = lazy(() => import("pages/upload/index"));
const DYNewUpLoad = lazy(() => import("pages/upload/index"));
const JDUpLoad = lazy(() => import("pages/upload/index"));
const XHSUpLoad = lazy(() => import("pages/upload/index"));
const JSTupload = lazy(() => import("pages/upload/index"));

const WDUpLoad = lazy(() => import("pages/upload/wdupload/upload"));
const WXXDUpLoad = lazy(() => import("pages/upload/wxxdupload/upload"));
const YZUpLoad = lazy(() => import("pages/upload/yzupload/upload"));
const SNUpLoad = lazy(() => import("pages/upload/snupload/upload"));
const MGJUpLoad = lazy(() => import("pages/upload/mgjupload/upload"));
const WPHUpLoad = lazy(() => import("pages/upload/wphupload/upload"));
const WPHYsUpLoad = lazy(() => import("pages/upload/vipgysupload/upload"));
const XPUpLoad = lazy(() => import("pages/upload/xpupload/upload"));
const UploadLog = lazy(() => import("pages/person/uploadlog/index"));

const Express = lazy(() => import("pages/person/express/express"));
const Article = lazy(() => import("pages/article/article"));
const Freight = lazy(() => import("pages/freight/freight"));
const OffShelfGoods = lazy(() => import("pages/offShelfGoods"));
const BrandApply = lazy(() => import("pages/brandapply/brandapply"));
const AboutUs = lazy(() => import("pages/aboutus/aboutus"));
const Brand = lazy(() => import("pages/brand/brand"));
const H5 = lazy(() => import("pages/h5/h5"));
const Demo = lazy(() => import("pages/demo/demo"));
const Routers = [
  {
    path: "/login",
    component: Login,
    auth: false,
  },
  {
    path: "/reset_psd",
    component: ResetPsd,
    auth: false,
  },
  {
    path: "/register",
    component: Register,
    auth: false,
  },
  {
    path: "/wxlogin",
    component: WxLogin,
    auth: false,
  },
  // {
  //   path: "/test",
  //   component: Test,
  //   auth: false,
  // },
  // {
  //   path: "/home",
  //   component: Home,
  //   title: "首页",
  //   auth: false,
  // },
  {
    path: "/home",
    component: NewHome,
    title: "首页",
    auth: false,
  },
  {
    path: "/explosion",
    component: Explosion,
    auth: false,
  },
  {
    path: "/homevideo",
    component: HomeVideo,
    auth: false,
  },
  {
    path: "/merchandise/:id",
    component: Merchandise,
    title: "详情页",
    auth: false,
  },
  {
    path: "/goodsrank",
    component: GoodsRank,
    auth: false,
  },
  {
    path: "/wealth",
    component: Wealth,
    auth: false,
  },
  {
    path: "/wealth_raffle",
    component: WealthRaffle,
    auth: false,
  },
  {
    path: "/wealth_invite",
    component: WealthInvitation,
    auth: false,
  },
  {
    path: "/merchant/:id",
    component: Merchant,
    auth: false,
  },
  {
    path: "/merchantGoods/:id",
    component: MerchantGoods,
    auth: false,
  },
  {
    path: "/special",
    component: Special,
    auth: false,
  },
  {
    path: "/Cutout",
    component: Cutout,
    auth: false,
  },
  {
    path: "/subject",
    component: Subject,
    auth: false,
  },
  {
    path: "/fabric",
    component: Fabric,
    auth: false,
  },
  {
    path: "/erp_apply",
    component: ErpApply,
    auth: false,
  },
  // {
  //   path: "/search",
  //   component: Search,
  //   auth: false,
  // },
  {
    path: "/search",
    component: NewSearch,
    title: "搜索页",
    auth: false,
  },
  {
    path: "/search_store",
    component: Search,
    title: "搜索页",
    auth: false,
  },

  {
    path: "/sole",
    component: Sole,
    auth: false,
  },
  {
    path: "/is_main",
    component: IsMain,
    auth: false,
  },
  {
    path: "/b",
    component: B,
    auth: false,
  },
  
  {
    path: "/new_day",
    component: NewDay,
    auth: false,
  },
  {
    path: "/xsj",
    component: NewDay,
    auth: false,
  },
  {
    path: "/photography",
    component: NewDay,
    auth: false,
  },
  {
    path: "/new_seven_day",
    component: NewDay,
    auth: false,
  },
  {
    path: "/upscale",
    component: NewDay,
    auth: false,
  },
  {
    path: "/winter",
    component: NewDay,
    auth: false,
  },
  {
    path: "/springSummer",
    component: NewDay,
    auth: false,
  },

  {
    path: "/seven_down",
    component: SevenDown,
    auth: false,
  },
  {
    path: "/soutu_hot",
    component: SoutuHot,
    auth: false,
  },
  {
    path: "/popular_products",
    component: PopularProducts,
    auth: false,
  },

  {
    path: "/yjdf",
    component: YJDf,
    auth: false,
  },
  {
    path: "/mh",
    component: MH,
    auth: false,
  },
  {
    path: "/nh",
    component: NH,
    auth: false,
  },

  {
    path: "/offProducts",
    component: OffProducts,
    auth: false,
  },
  {
    path: "/strength_rank",
    component: StrengthRank,
    auth: false,
  },
  {
    path: "/online_retailer_tool",
    component: OnlineRetailerTool,
    auth: false,
  },
  {
    path: "/searchImg",
    component: SearchImg,
    auth: false,
  },
  {
    path: "/yiFang",
    component: YiFang,
    auth: false,
  },
  {
    path: "/yiFangInfo/:id",
    component: YiFangInfo,
    auth: false,
  },
  {
    path: "/searchassistant",
    component: SearchAssistant,
    auth: false,
  },
  {
    path: "/services-market",
    component: ServicesMarket,
    auth: false,
  },
  {
    path: "/services-market-info/:id",
    component: ServicesMarketinfo,
    auth: false,
  },
  {
    path: "/deliver",
    component: Deliver,
    auth: false,
  },
  {
    path: "/shopcar",
    component: ShopCar,
    auth: true,
  },
  {
    path: "/order",
    component: Order,
    auth: true,
  },
  {
    path: "/orderpay",
    component: OrderPay,
    auth: true,
  },
  {
    path: "/orderpayok",
    component: OrderPayOk,
    auth: true,
  },
  {
    path: "/wxpay",
    component: WxPay,
    auth: true,
  },
  {
    path: "/myorderlist",
    component: MyOrderList,
    auth: true,
  },
  {
    path: "/apply",
    component: Apply,
    auth: true,
  },
  {
    path: "/apply_stickers",
    component: ApplyStickers,
    auth: false,
  },
  {
    path: "/application_reimbursement",
    component: ApplicationReimbursement,
    auth: true,
  },
  {
    path: "/copyall",
    component: CopyAll,
    auth: true,
  },
  {
    path: "/copylog",
    component: CopyLog,
    auth: true,
  },
  {
    path: "/copybag",
    component: CopyBag,
    auth: true,
  },
  {
    path: "/pddorderlist",
    component: PddOrderList,
    // auth: true,
  },
  {
    path: "/pddgoodslist",
    component: PddGoodsList,
    auth: true,
  },
  {
    path: "/aligoodslist",
    component: AliGoodsList,
    auth: true,
  },
  {
    path: "/aligoodslistjc",
    component: AliGoodsListJC,
    auth: true,
  },
  {
    path: "/aligoodslistfz",
    component: AliGoodsListFZ,
    auth: true,
  },
  {
    path: "/authorize",
    component: Authorize,
    auth: true,
  },
  {
    path: "/shared_account",
    component: SharedAccount,
    auth: true,
  },
  {
    path: "/returnlist",
    component: ReturnList,
    auth: true,
  },
  {
    path: "/returnDetail/:id",
    component: ReturnDetail,
    auth: true,
  },
  {
    path: "/applyRefund/:id",
    component: ApplyRefund,
    auth: true,
  },
  {
    path: "/address",
    component: Address,
    auth: true,
  },
  {
    path: "/sender",
    component: Sender,
    auth: true,
  },
  {
    path: "/refund",
    component: Refund,
    auth: true,
  },
  {
    path: "/orderDetail/:id",
    component: OrderDetail,
    auth: true,
  },
  {
    path: "/goodsfav",
    component: GoodsFav,
    auth: true,
  },
  {
    path: "/uploadlog",
    component: UploadLog,
    auth: true,
  },
  {
    path: "/storefav",
    component: StoreFav,
    auth: true,
  },
  {
    path: "/ksupload",
    component: KSUpLoad,
    auth: true,
  },
  {
    path: "/dyupload",
    component: DYUpLoad,
    auth: true,
  },
  {
    path: "/dynewupload",
    component: DYNewUpLoad,
    auth: true,
  },
  {
    path: "/pddupload",
    component: PDDUpLoad,
    auth: true,
  },
  {
    path: "/aliupload",
    component: AliUpLoad,
    auth: true,
  },
  {
    path: "/tbupload",
    component: TBUpLoad,
    auth: true,
  },
  {
    path: "/wdupload",
    component: WDUpLoad,
    auth: true,
  },
  {
    path: "/wxxdupload",
    component: WXXDUpLoad,
    auth: true,
  },
  {
    path: "/xhsupload",
    component: XHSUpLoad,
    auth: true,
  },
  {
    path: "/jstupload",
    component: JSTupload,
    auth: true,
  },
  {
    path: "/yzupload",
    component: YZUpLoad,
    auth: true,
  },
  {
    path: "/snupload",
    component: SNUpLoad,
    auth: true,
  },
  {
    path: "/mgjupload",
    component: MGJUpLoad,
    auth: true,
  },
  {
    path: "/vipupload",
    component: WPHUpLoad,
    auth: true,
  },
  {
    path: "/vipgysupload",
    component: WPHYsUpLoad,
    auth: true,
  },

  {
    path: "/tmupload",
    component: TMUpLoad,
    auth: true,
  },
  {
    path: "/xpupload",
    component: XPUpLoad,
    auth: true,
  },
  {
    path: "/jdupload",
    component: JDUpLoad,
    auth: true,
  },
  {
    path: "/express/:id",
    component: Express,
    auth: true,
  },
  {
    path: "/article/:id/:id",
    component: Article,
    auth: false,
  },
  {
    path: "/freight",
    component: Freight,
    auth: false,
  },
  {
    path: "/offShelfGoods",
    component: OffShelfGoods,
    auth: false,
  },
  {
    path: "/brandapply",
    component: BrandApply,
    auth: false,
  },
  {
    path: "/aboutus",
    component: AboutUs,
    auth: false,
  },
  {
    path: "/brand",
    component: Brand,
    auth: false,
  },
  {
    path: "/h5",
    component: H5,
    auth: false,
  },
  {
    path: "/demo",
    component: Demo,
    auth: false,
  },
];
export default Routers;
